import { TextValueExtended } from "../TextValueItem";

export class FiltroQueryAvanzato {
	/**
	 *
	 */
	constructor() {
		this.index = 0;
		this.tipo = "";
		this.id = "";
		this.itm = 0;
		this.testo = "";

		this.elements = [];
		this.elementSelected = null;

		this.objSelected = null;
	}

	index: number; //cnt
	tipo: string;
	id: string;
	itm: number;    
	testo: string;

	elements: TextValueExtended[];
	elementSelected: TextValueExtended;

	objSelected: any;
}