export class StatisticheQueryElenco {
	/**
	 *
	 */
	constructor() {
		this.itemID = 0;
		this.categoria = "";
		this.nomeQuery = "";
		this.descrizioneQuery = "";
		this.strSQL = "";
		this.ruoliAmmessi = "";
		this.filtriSQL = "";
		this.jsonGrafico = "";
		this.tipoUtente = "";
	}

	itemID: number;
	categoria: string;
	nomeQuery: string;
	descrizioneQuery: string;
	strSQL: string;
	ruoliAmmessi: string;
	filtriSQL: string;
	jsonGrafico: string;
	tipoUtente: string;
}