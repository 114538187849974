// import Vue from 'vue'
import Vue from "vue";
import baseContextComponent from "@/components/baseContextComponent";
import { Component } from "vue-property-decorator";
import api from "@/services/api";
import { QueryDTO } from "@/models/analisiAvanzata/queryDTO";
import { QueryCategories } from "@/models/analisiAvanzata/queryCategories";
import { StatisticheQueryElenco } from "@/models/analisiAvanzata/statisticheQueryElenco";
import { FiltroQueryAvanzato } from "@/models/analisiAvanzata/filtroQueryAvanzato";
import { ListaValoriRequest } from "@/models/analisiAvanzata/listaValoriRequest";
import { BIconMicFill, SpinnerPlugin } from "bootstrap-vue";
import gridComponentBase from "@/components/grid/gridComponentBase.vue";
import { filterBy, orderBy, process } from "@progress/kendo-data-query";
import helpers from "@/helpers";

import jQuery from "jquery";
import permessi from "@/config/permessi";
import moment from "moment";

@Component({})
export default class fornitoriPage extends gridComponentBase {
	get PERMESSI(): string[] {
		return [permessi.BK.Vendite.ANALISIAVANZATA];
	}
	queries: QueryCategories[] = [];
	querySelected: StatisticheQueryElenco = null;

	elencoFiltri: FiltroQueryAvanzato[] = [];
	columns: any[] = [];

	items: any[];

	grid: any = null;

	created() {
		this.getCatgeories();
		(window as any).$ = jQuery;
	}

	mounted() {
		this.grid = jQuery("#queryResult");
	}

	beforeDestroy() {
		console.log("destroy");
		(window as any).$ = undefined;
	}

	getCatgeories() {
		var self = this;
		api.getQueryGroupCategoryAnalisiAvanzata()
			.then(res => {
				self.queries = res;
			})
			.catch(err => {});
	}

	getData() {
		var items = this.items;
		if (this.filter && this.filter.filters) {
			items = filterBy(this.items, this.filter.filters as any);
		}

		items = orderBy(items, this.sort);
		this.gridData = process(items, { take: this.take, skip: this.skip, group: this.group });
	}

	setSelectedQuery(p: QueryDTO) {
		var self = this;
		api.getQueryDetailByItemId(p.itemID)
			.then(res => {
				self.querySelected = res;
				self.creaParametri();
			})
			.catch(err => {
				console.log(err);
			});
	}

	creaParametri() {
		this.elencoFiltri = [];

		var strSQL = this.querySelected.strSQL;
		var a = 0,
			b = 0,
			c = 0,
			cnt = 1;

		while (a != -1) {
			a = strSQL.indexOf("?", a + 1);
			if (a >= 0) {
				b = strSQL.indexOf("[", a);
				c = strSQL.indexOf("]", b);
				var testo = strSQL.substring(b + 1, c);
				var tipo = strSQL.substring(a + 1, b).toLowerCase();

				if (tipo == "data") {
					var filtro = new FiltroQueryAvanzato();
					filtro.index = cnt;
					filtro.tipo = "data";
					filtro.testo = testo;

					this.elencoFiltri.push(filtro);
				}
				if (tipo == "string") {
					var filtro = new FiltroQueryAvanzato();
					filtro.index = cnt;
					filtro.tipo = "string";
					filtro.testo = testo;
					filtro.elementSelected = null;

					this.elencoFiltri.push(filtro);
				}
				if (tipo.substring(0, 3) == "sql") {
					b = strSQL.indexOf("{", a);
					c = strSQL.indexOf("}", b);
					var id = strSQL.substring(b + 1, c);
					var itm = this.querySelected.itemID;

					var filtro = new FiltroQueryAvanzato();
					filtro.index = cnt;
					filtro.id = id;
					filtro.itm = itm;
					filtro.tipo = tipo;
					filtro.testo = testo;

					filtro.elements = [];

					this.elencoFiltri.push(filtro);
				}

				cnt++;
			}
		}

		//console.log(this.elencoFiltri);

		var _getListaValoriPerFiltro = function(filtro: FiltroQueryAvanzato) {
			var data: ListaValoriRequest = {
				lista: "filtroQuery",
				parametri: [filtro.itm, filtro.id]
			};

			api.getListaValori(data)
				.then(res => {
					filtro.elements = res;
				})
				.catch(err => {
					console.log(err);
				});
		};

		for (var i = 0; i < this.elencoFiltri.length; i++) {
			var filtro = this.elencoFiltri[i];

			if (filtro.tipo.substring(0, 3) !== "sql") {
				continue;
			}

			_getListaValoriPerFiltro(filtro);
		}
	}

	onClickHeader(q: QueryCategories) {
		Vue.set(q, "visible", !q.visible);
	}

	eseguiQuery() {
		var parametri = [];

		this.destroyGrid();

		for (var i = 0; i < this.elencoFiltri.length; i++) {
			var filtro = this.elencoFiltri[i];

			if( filtro.tipo === "string"){
				parametri.push(filtro.elementSelected || "");
			}
			else if (filtro.elementSelected) {
				parametri.push(filtro.elementSelected.code);
			} else if (filtro.objSelected) {
				parametri.push(filtro.objSelected);
			}
		}

		var self = this;

		api.getQueryValues(this.querySelected.itemID, parametri)
			.then(res => {
				if (res.errorMessage) {
					self.$bvToast.toast("Sintassi non corretta. Verificare il testo inserito", {
						variant: "warning",
						title: self.$i18n.t("msg.erroreGenerico").toString(),
						solid: true
					});

					return;
				}
				if (res.result.length == 0) {
					self.$bvToast.toast("Nessun Risultato per la query eseguita", {
						variant: "warning",
						title: self.$i18n.t("msg.erroreGenerico").toString(),
						solid: true
					});

					return;
				}

				var cols = [];
				var resFields = res.tipoCampi;
				var campiNoTotali = ["itemid", "numeroadesione", "adesione", "adesioneid", "distributoreid", "pacchettoid", "prodottoid"];
				for (var n in resFields) {
					var c = { field: n, title: n, export: true, hidden: false } as any;
					resFields[n] === "date" && (c.format = "{0:dd/MM/yyyy}");
					//resFields[n] === "date" && (c.type = "date");
					if (resFields[n] === "number" && campiNoTotali.indexOf(n.toLowerCase()) === -1) {
						c.format = "{0:n2}";
						c.groupable = false;
						c.aggregates = ["sum"];
						c.footerTemplate = "Tot: #=kendo.toString(sum,'n2')#";
						c.groupFooterTemplate = "Tot: #=kendo.toString(sum,'n2')#";
					}
					if (resFields[n] === "int" && campiNoTotali.indexOf(n.toLowerCase()) === -1) {
						c.groupable = false;
						c.aggregates = ["sum"];
						c.footerTemplate = "Tot: #=kendo.toString(sum,'n2')#";
						c.groupFooterTemplate = "Tot: #=kendo.toString(sum,'n2')#";
					}
					c.width = 150;
					cols.push(c);
				}

				self.columns = cols;
				//console.log(cols);
				self.items = res.result;

				//self.getData();

				var dataSource = self.createGridDataSource(res.result, resFields);

				self.grid.kendoGrid({
					dataSource: dataSource,
					columns: self.columns,
					scrollable: true,
					resizable: true,
					reorderable: true,
					columnMenu: {
						messages: {
							sortAscending: "Ordinamento ascendente",
							sortDescending: "Ordinamento discendente",
							filter: "Filtra",
							columns: "Colonne"
						}
					},
					toolbar: [{ name: "excel", text: "Esporta Excel" }, { template: "<a class='btnAzzeraFiltriClienti k-button'>Azzera filtri e ordinamenti</a>" }],
					excel: {
						allPages: true
					},
					//  reorderable: true,
					//   sortable:true,
					pageable: {
						pageSize: 50
					},
					groupable: {
						messages: {
							empty: "&nbsp;Trasporta una colonna per Raggruppare"
						}
					},
					sortable: {
						mode: "multiple"
					},
					editable: false,
					filterable: {
						messages: {
							isTrue: "Si",
							isFalse: "No",
							selectValue: "Seleziona",
							info: "Filtra per:",
							and: "e",
							or: "oppure",
							filter: "Applica",
							clear: "Rimuovi"
						},
						operators: {
							selectValue: "Scegli",
							operator: "Operatore",
							value: "Valore",
							string: {
								contains: "Contiene",
								eq: "Uguale a",
								neq: "Diverso da",
								startswith: "Comincia per",
								doesnotcontain: "Non contiene",
								endswith: "Finisce per"
							},
							number: {
								eq: "Uguale a",
								neq: "Diverso da",
								gte: "Maggiore o uguale a",
								gt: "Maggiore di",
								lte: "Minore o uguale a",
								lt: "Minore di"
							},
							date: {
								gt: "Dopo",
								lt: "Prima",
								gte: "Dopo o uguale a",
								lte: "Prima o uguale a"
							},
							enums: {
								eq: "Uguale a",
								neq: "Diverso da"
							}
						}
					}
				});

				var btnAzzeraFiltri = self.grid.find(".btnAzzeraFiltriClienti");
				btnAzzeraFiltri.on("click", function() {
					var g = self.grid.data("kendoGrid"),
						ds = g.dataSource,
						currPageSize = ds.pageSize(),
						aggregate = ds.aggregate();
					ds.query({ filter: null, sort: null, group: [], page: 1, pageSize: currPageSize, aggregate: aggregate });
				});
			})
			.catch(err => {
				console.log(err);
			});
	}

	destroyGrid() {
		var g = this.grid.data("kendoGrid");
		g && g.destroy();
		this.grid.empty();
	}

	createGridDataSource(dati, resFields) {
		var fields = {};
		for (var n in resFields) fields[n] = { type: resFields[n] };

		var aggregate = [];
		for (var n in resFields) {
			if (resFields[n] === "number" || resFields[n] === "int") {
				n.toLowerCase() !== "itemid" && aggregate.push({ field: n, aggregate: "sum" });
			}
		}

		return new kendo.data.DataSource({
			data: dati,
			pageSize: 50,
			schema: {
				model: {
					fields: fields
				}
			},
			aggregate: aggregate
		});
	}

	exportExcel() {
		var exportFileName = "Export";
		var pageName = this.$router.currentRoute.name;

		if (pageName) {
			exportFileName = `Export - ${pageName} - ${moment().format("YYYYMMDD")}`;
		}

		this.exportExcelWithHiddenCreate(this.items as any, );
	}

	onSalvaNuovaQuery() {
		var data = helpers.cloneObject(this.querySelected);
		data.itemID = 0;

		var self = this;

		api.updateQuery(data)
			.then(res => {
				self.$bvToast.toast(this.$i18n.tc("msg.salvato_iCorrettamente", 1, { obj: "query" }).toString(), {
					variant: "success",
					title: this.$i18n.t("msg.salvataggioRiuscito").toString(),
					solid: true
				});

				self.querySelected = null;
				self.getCatgeories();
			})
			.catch(err => {
				console.log(err);
			});
	}

	onSalvaQuery() {
		var self = this;

		api.updateQuery(this.querySelected)
			.then(res => {
				self.$bvToast.toast(this.$i18n.tc("msg.salvato_iCorrettamente", 1, { obj: "query" }).toString(), {
					variant: "success",
					title: this.$i18n.t("msg.salvataggioRiuscito").toString(),
					solid: true
				});
			})
			.catch(err => {
				console.log(err);
			});
	}

	onDeleteQuery() {
		var self = this;
		var item = this.querySelected;

		var message = self.$createElement("div", { domProps: { innerHTML: `<h5>${item.nomeQuery}</h5>${self.$i18n.t("messaggio.confermaCancellazione")}` } }); // h('div', { domProps: { innerHTML: 'Title from <i>HTML<i> string' } })
		var title = this.$i18n.t("titolo.elimina", [this.$i18n.t("generico.query")]).toString();

		self.$bvModal
			.msgBoxConfirm([message], {
				title: title,
				okVariant: "warning",
				okTitle: self.$i18n
					.t("btn.si")
					.toString()
					.toUpperCase(),
				cancelTitle: self.$i18n
					.t("btn.no")
					.toString()
					.toUpperCase(),
				cancelVariant: "outline-theme",
				footerClass: "",
				headerBgVariant: "warning"
				// centered: true
			})
			.then(value => {
				if (value) {
					api.delete("query", item.itemID)
						.then(res => {
							self.getCatgeories();
						})
						.catch(err => console.log(err));
				}
			})
			.catch(err => {
				// An error occurred
			});
	}

	onPulisciQuery() {
		this.querySelected.categoria = "";
		this.querySelected.nomeQuery = "";
		this.querySelected.descrizioneQuery = "";
		this.querySelected.strSQL = "";
	}

	get noQuerySelected() {
		if (this.querySelected) return false;

		return true;
	}

	get columnsGrid() {
		if (this.columns && this.columns.length) return this.columns;

		return [];
	}

	get categoriesQuery() {
		return this.queries;
	}

	onNewQuery() {
		this.querySelected = new StatisticheQueryElenco();
	}
}
